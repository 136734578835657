import React from 'react';
import "./ForgotPasswordPage.scss"
import ForgotPasswordComponent from '../../components/ForgotPasswordComponent/ForgotPasswordComponent';

export default function ForgotPasswordPage({URL, CLIENT_URL}) {

  return (
    <div className="form-background">
<ForgotPasswordComponent/>
    </div>
  );
}
